.banner {
  color: white;
  object-fit: contain;
  height: 448px;
  margin-bottom: 1rem;
}

.banner_contents {
  margin-left: 30px;
  padding-top: 140px;
  height: 190px;
}

.banner_title {
  font-size: 3rem;
  font-weight: 800;
  padding-bottom: 0.3rem;
}

.banner_description {
  width: 45rem;
  line-height: 1.3;
  padding-top: 1rem;
  font-size: 0.8rem;
  max-width: 360px;
  height: 80px;
}
.banner_buttons {
  display: flex;
}

.banner_button {
  cursor: pointer;
  color: white;
  outline: none;
  border: none;
  font-weight: 700;
  border-radius: 0.4vw;
  padding: 0 2rem;
  /* //may need adjustign */
  margin-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 5px;
}

.banner_btn_play {
  background-color: rgba(255, 255, 255);
  color: black;
}

.banner_btn_play:hover {
  background-color: rgba(255, 255, 255, 0.8);
  transition: all 0.2s;
}
.upside_down {
  transform: scaleX(-1);
}
.banner_btn_info {
  background-color: rgba(119, 119, 119, 0.7);
}

.banner_btn_info:hover {
  background-color: rgba(109, 109, 110, 0.6);
  transition: all 0.2s;
}

.banner_fadeBottom {
  height: 7.4rem;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(37, 37, 37, 0.61)
  );
}
