.navbar {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  z-index: 1;

  transition-timing-function: ease-in;
  transition: all 0.5s;
  align-items: center;

  background-image: linear-gradient(
    360deg,
    transparent,
    rgba(37, 37, 37, 0.61)
  );
}

.nav_black {
  background-color: #111;
}

.nav_logo {
  object-fit: contain;
  width: 100px;
}

.nav_icon {
  /* position: fixed; */
  width: 30px;
  object-fit: contain;
  /* right: 20px; */
}

.nav_list {
  display: flex;
  margin-right: auto;
  color: #e5e5e5;
  text-decoration: none;
  font-size: 0.8rem;
  margin-left: 2rem;
  cursor: pointer;
}

li:hover {
  transition: all 0.4s;
  color: rgba(229, 229, 229, 0.7);
}

li {
  list-style-type: none;
  margin: 0 0.7rem;
  padding: 0;
}

.nav_icons {
  display: flex;
  align-items: center;
  margin-right: 40px;
  gap: 10px;
  color: white;
}
