.footer_container {
  height: 350px;
  padding-top: 6rem;
  color: rgba(229, 229, 229, 0.9);
  line-height: 2rem;
  font-size: 0.8rem;

  display: flex;
  justify-content: center;
}

p:hover {
  transition: all 0.4s;
  color: rgba(229, 229, 229, 0.7);
  cursor: pointer;
}

.service_code_btn {
  border: 1px solid rgba(229, 229, 229, 0.7);
  background-color: #111;
}
